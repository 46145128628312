<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="salarieData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Salaire
            </h6>
          </b-col>

          <b-col cols="8">
            <b-button
              v-if="$router.name == 'salarie-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'salarie-view',
                  params: { id: salarieData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'salarie-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'salarie-view',
                  params: { id: salarieData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'salarie-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline"
                >Liste des categories salariales</span
              >
            </b-button>
          </b-col>
        </b-row>

        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="InfoIcon" size="18" />
              <span class="align-middle ml-50">Généralités</span>
            </b-card-title>
          </b-card-header>

          <b-card-body>
            <b-row class="">
              <!-- id_salary -->
              <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="id_salary"
                  rules="required"
                >
                  <b-form-group label="Code" label-for="id_salary">
                    <b-form-input
                      id="id_salary"
                      v-model="salarieData.id_salary"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: Niveau-1"
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Completer code salaire
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- base_salary -->
              <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="base_salary"
                  rules="required"
                >
                  <b-form-group
                    label="Salaire de Base ($)"
                    label-for="base_salary"
                  >
                    <b-form-input
                      id="description"
                      v-model="salarieData.base_salary"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: 400"
                      type="number"
                      step="any"
                      class=""
                      @input="netSalaryCalculateUpdate"
                      @change="netSalaryCalculateUpdate"
                    />
                    <b-form-invalid-feedback>
                      Completer (Montant salaire de base)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- days -->
              <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="days"
                  rules="required"
                >
                  <b-form-group label="Tot. Jrs à Prester" label-for="days">
                    <b-form-input
                      id="days"
                      v-model="salarieData.days"
                      :state="getValidationState(validationContext)"
                      trim
                      type="number"                      
                      @input="calculateBaseDaySalary"
                      @change="calculateBaseDaySalary"
                      placeholder="Ex: 22"
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Completer total jours à prester
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- unitaire jr -->
              <b-col cols="6" md="3">
                <validation-provider
                  #default="validationContext"
                  name="unit_amount"
                  rules="required"
                >
                  <b-form-group label="Taux/Jr ($/USD)" label-for="unit_amount">
                    <b-form-input
                      id="unit_amount"
                      v-model="salarieData.unit_amount"
                      :state="getValidationState(validationContext)"
                      trim
                      type="number"
                      step="any"
                      placeholder="Ex: 20"                      
                      readonly
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Completer taux du jour salaire
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- description -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="description"
                  rules="required"
                >
                  <b-form-group label="Description" label-for="description">
                    <b-form-input
                      id="description"
                      v-model="salarieData.description"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: Salaire de Niveau 1"
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Completer Description
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Identity -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="ListIcon" size="18" />
              <span class="align-middle ml-50">Indemnités</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div ref="form" class="repeater-form">
              <!-- Avantages -->
              <b-row
                class="pb-2"
                v-for="(module, e_index) in salarieData.endemnities"
                :key="e_index"
                ref="row"
              >
                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    :name="'name.indemnity.' + e_index"
                    rules="required"
                  >
                    <b-form-group :label-for="'name.indemnity.' + e_index">
                      <label
                        ><span class="text-primary">
                          Designation de l'indemnité
                        </span>
                        {{ e_index + 1 }}
                      </label>
                      <b-form-input
                        :id="'name.indemnity.' + e_index"
                        v-model="salarieData.endemnities[e_index].name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Soins Médicaux"
                      />
                      <b-form-invalid-feedback>
                        Completer l'Indemnité {{ e_index + 1 }} s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    :name="'amount.indemnity.' + e_index"
                    rules="required"
                  >
                    <b-form-group
                      label="Montant"
                      :label-for="'amount.indemnity.' + e_index"
                    >
                      <b-form-input
                        :id="'amount.indemnity.' + e_index"
                        v-model="salarieData.endemnities[e_index].amount"
                        trim
                        type="number"
                        step="any"
                        placeholder="Ex: 100"
                        :state="getValidationState(validationContext)"
                        class=""
                        @input="netSalaryCalculateUpdate"
                        @change="netSalaryCalculateUpdate"
                      />
                      <b-form-invalid-feedback>
                        Completer le montant {{ e_index + 1 }} s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group>
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeEndemnity(e_index)"
                    >
                      <feather-icon icon="XIcon" class="mr-10" />
                      <span>Supprimer</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-sm float-left"
              @click="addNewStepForm"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="xl-none">Ajouter Indemnité</span>
            </b-button>
          </b-card-body>
        </b-card>

        <b-card no-body class="border mt-1">
          <b-card-body>
            <h1>
              Salaire Brut:
              {{
                parseFloat(salarieData.base_salary) +
                salarieData.endemnities.reduce(
                  (n, { amount }) => n + parseFloat(amount),
                  0
                )
              }}
              USD
            </h1>
            <!-- salarieData.base_salary -->
          </b-card-body>
        </b-card>

        <!-- retains / retenus -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="ListIcon" size="18" />
              <span class="align-middle ml-50">Retenus</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div ref="form" class="repeater-form">
              <!-- Avantages -->
              <b-row
                class="pb-2"
                v-for="(module, e_index) in salarieData.retains"
                :key="e_index"
                ref="row"
              >
                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    :name="'name.retain.' + e_index"
                    rules="required"
                  >
                    <b-form-group :label-for="'name.retain.' + e_index">
                      <label
                        ><span class="text-primary"> Nom du retenu </span>
                        {{ e_index + 1 }}
                      </label>
                      <b-form-input
                        :id="'name.retain.' + e_index"
                        v-model="salarieData.retains[e_index].name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: IPR"
                      />
                      <b-form-invalid-feedback>
                        Completer l'Indemnité {{ e_index + 1 }} s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    :name="'amount.retain.' + e_index"
                    rules="required"
                  >
                    <b-form-group
                      label="Montant"
                      :label-for="'amount.retain.' + e_index"
                    >
                      <b-form-input
                        :id="'amount.retain.' + e_index"
                        v-model="salarieData.retains[e_index].amount"
                        trim
                        type="number"
                        step="any"
                        placeholder="Ex: 100"
                        :state="getValidationState(validationContext)"
                        class=""
                        @input="netSalaryCalculateUpdate"
                        @change="netSalaryCalculateUpdate"
                      />
                      <b-form-invalid-feedback>
                        Completer le montant {{ e_index + 1 }} s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Opérateur sur Salaire Brut"
                    :label-for="'operator.retain.' + e_index"
                  >
                    <b-form-input
                      :id="'operator.retain.' + e_index"
                      v-model="salarieData.retains[e_index].operator"
                      trim
                      placeholder="Ex: + * - / %"
                      class=""
                      @input="netSalaryCalculateUpdate"
                      @change="netSalaryCalculateUpdate"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group>
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeRetain(e_index)"
                    >
                      <feather-icon icon="XIcon" class="mr-10" />
                      <span>Supprimer</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-sm float-left"
              @click="addNewRetain"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="xl-none">Ajouter Retenu</span>
            </b-button>
          </b-card-body>
        </b-card>

        <b-card no-body class="border mt-1">
          <b-card-body>
            <h1>
              Salaire Net:
              {{ netSalaryCalculationValue }}
              USD
            </h1>
            <!-- salarieData.base_salary -->
          </b-card-body>
        </b-card>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="salarieData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "salarie";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      imageFile: null,
      actionIndex: 0,
      sku_salarie: "",
      image_url: "",
      image_changed: false,
      showed: true,
      loading: false,
      server_url: $themeConfig.app.remote_server_url,
      branchOptions: [],
      agentListOptions: [],
      departmentOptions: [],
      netSalaryCalculationValue: 0,
    };
  },
  props: {
    salarieData: {
      type: Object,
    },
    defaultsalarieData: {
      type: Object,
    },
    salarieformblankData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() { 

    if (router.currentRoute.name == moduleName + "-add-new") {

      var now = new Date();
      this.sku_salarie = "ag-" + now.getTime();

      this.operationTitle = "Parametrage de ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {

      this.sku_salarie = router.currentRoute.params.id;
      this.operationTitle = "Détails de l'";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;

      this.netSalaryCalculateUpdate();

    }
    
    if (router.currentRoute.name == moduleName + "-edit") {

      this.sku_salarie = router.currentRoute.params.id;
      this.operationTitle = "Modification de l'";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;

      this.netSalaryCalculateUpdate();

    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateBaseDaySalary() {
      // base_salary
      if (this.salarieData.days > 0) {       
        const unit_amount = parseFloat( parseFloat(this.salarieData.base_salary) / this.salarieData.days);
        this.salarieData.unit_amount = parseFloat(unit_amount);
      } 
    },
    netSalaryCalculateUpdate() {
      
      // brut
      const brut_salary = parseFloat(
        parseFloat(this.salarieData.base_salary) +
          this.salarieData.endemnities.reduce(
            (n, { amount }) => n + parseFloat(amount),
            0
          )
      );

      var sumRetains = parseFloat("0");

      for (var i = 0; i < this.salarieData.retains.length; i++) {
        var amount = this.salarieData.retains[i].amount;
        var operator = this.salarieData.retains[i].operator;
        if (amount != "" && amount > 0) {
          if (operator != "") {
            switch (operator) {
              case "-":
                sumRetains = sumRetains + parseFloat(amount);
                break;
              case "%":
                sumRetains =
                  sumRetains + parseFloat((amount * brut_salary) / 100);
                break;
              default:
                console.log(
                  `Operateur Arthimetique non prise en charge pour les retenus ${operator}.`
                );
            }
          } else {
            // pas d'operator, on soustrait
            sumRetains = sumRetains + parseFloat(amount);
          }
        } else {
          // no amount
        }
      }

      // finalize
      this.salarieData.brut_salary = parseFloat(brut_salary);

      if(this.salarieData.days>0){
        // payement par jour  = montant total salaire brut / nombre de jours
        this.salarieData.unit_amount = this.salarieData.base_salary / this.salarieData.days
      }       

      this.netSalaryCalculationValue = parseFloat(brut_salary - sumRetains);
      // formData
      this.salarieData.net_salary = parseFloat(this.netSalaryCalculationValue);
    },    
    addNewStepForm() {
      this.$props.salarieData.endemnities.push(
        JSON.parse(JSON.stringify(this.$props.salarieformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    addNewRetain() {
      this.$props.salarieData.retains.push(
        JSON.parse(JSON.stringify(this.$props.salarieformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    addNewDepartmentInEmployerForm(module_index) {
      // this.$refs.form[0].style.overflow = "hidden";
      this.$props.salarieData.endemnities[module_index].items.push(
        JSON.parse(JSON.stringify(this.$props.itemformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    updateEmployerForm(e_index, index, val) {
      const { cost, qty, buy_unit, description } = val;
      // ---------------------------------------------------------
      this.$props.salarieData.endemnities[e_index].items[index].cost = cost;
      this.$props.salarieData.endemnities[e_index].items[index].qty_unit =
        buy_unit;
      if (
        this.$props.salarieData.endemnities[e_index].items[index].qty == null ||
        this.$props.salarieData.endemnities[e_index].items[index].qty ==
          undefined
      ) {
        // default qty
        this.$props.salarieData.endemnities[e_index].items[index].qty = 1;
      }
      this.$props.salarieData.endemnities[e_index].items[index].description =
        description;
    },
    removeEndemnity(e_index) {
      this.$props.salarieData.endemnities.splice(e_index, 1);
      this.netSalaryCalculateUpdate();
      // update design heigth
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    removeRetain(e_index) {
      this.$props.salarieData.retains.splice(e_index, 1);
      this.netSalaryCalculateUpdate();
      // update design heigth
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    reinitForm() {
      this.$props.salarieData = this.defaultsalarieData;

      if (router.currentRoute.name == "salarie-add-new") {
        // init empty salarie data
        // salarieData = this.defaultsalarieData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     salarieData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       salarieData.value = undefined;
        //     }
        //   });
      }
      //

      // remove all occurences
      // initialise 1 element
      // this.$props.salarieData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.salarieData.name == "") {
        this.errors.push("Compléter le nom du salarie svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "salarie-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "salarie-view") {
        method = "get";
        params = "/" + this.salarieData.id;
      }
      if (router.currentRoute.name == "salarie-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.salarieData.id;
      }

      this.netSalaryCalculateUpdate();

      // finalise form data
      // delete this.$props.moduleData.image_url;
      formData = this.$props.salarieData;

      await myApi({
        url: "salarie" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "salarie-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "salarie-view" });
    },
    goToNextItem() {
      router.push({ name: "salarie-list" });
    },
    goToList() {
      router.push({ name: "salarie-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-salarie";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetsalarieData = () => {
      // this.salarieData = this.$props.salarieData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsalarieData);

    var calculator = {
      "%": function (a, b) {
        return (a * 100) / b;
      },
      "+": function (a, b) {
        return a + b;
      },
      "-": function (a, b) {
        return a - b;
      },
      "/": function (a, b) {
        return a / b;
      },
      "<": function (a, b) {
        return a < b;
      },
    };

    // ...
    //
    // let itemsList = ref([]);
    // store.dispatch("app-salarie/fetchItems").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // fill items
    // let itemsOptions = itemsList;

    const genderOptions = [
      { label: "Homme", value: "M" },
      { label: "Femme", value: "F" },
      { label: "Autre", value: "-" },
    ];
    const maritalOptions = [
      { label: "Marié(e)", value: "maried" },
      { label: "Célibaire", value: "unmarried" },
      { label: "Autre", value: "-" },
    ];

    const immigrationStatusOptions = [
      { label: "Citoyen(e)", value: "citizen" },
      { label: "Résident(e) Permanent(e)", value: "permanent_resident" },
      { label: "Titulaire d'un Laisser Passer", value: "holder_of_a_pass" },
      {
        label: "Titulaire d'un Permis de Travail",
        value: "holder_of_a_work_permit",
      },
    ];

    const contractOptions = [
      { label: "CDI | Contrat à Durée Indeterminée", value: "CDI" },
      { label: "CDD | Contrat à Durée Determinée", value: "CDD" },
      { label: "Autre Contrat", value: "-" },
    ];

    const careerOptions = [
      { label: "Chef de projet", value: "-" },
      { label: "Ingénieur logiciel", value: "" },
      { label: "Ingénieur logiciel adjoint", value: "" },
      { label: "Ingénieur AQ", value: "" },
      { label: "Chef de produit", value: "" },
      { label: "Ingénieur QA adjoint", value: "" },
      { label: "Chef de projet technique", value: "" },
      { label: "Responsable avant-vente", value: "" },
    ];

    const payLevelOptions = [
      { label: "Gestionnaire", value: "manager" },
      { label: "Administrateur", value: "admin" },
      { label: "Exécutif", value: "executif" },
      { label: "Assistant", value: "assistant" },
    ];

    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.salarieData.avatar = base64;
      }
    );

    return {
      // itemsList,
      // itemsOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      immigrationStatusOptions,
      maritalOptions,
      genderOptions,
      refInputEl,
      previewEl,
      contractOptions,
      careerOptions,
      payLevelOptions,
      inputImageRenderer,
      avatarText,
      calculator,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
